const datas = [
    {
      "id": 1,
      "createdAt": "2023-09-21",
      "name": "John Doe",
      "email": "johndoe@example.com",
      "phone": "123-456-7890",
      "address": "123 Main Street",
      "streetAddress": "Apt 4B",
      "city": "New York",
      "state": "NY",
      "pinCode": "10001",
      "country": "USA",
      "gender": "Male",
      "hobbies": ["Reading", "Hiking"]
    },
    {
      "id": 2,
      "createdAt": "2023-09-20",
      "name": "Jane Smith",
      "email": "janesmith@example.com",
      "phone": "987-654-3210",
      "address": "456 Elm Street",
      "streetAddress": "Suite 301",
      "city": "Los Angeles",
      "state": "CA",
      "pinCode": "90001",
      "country": "USA",
      "gender": "Female",
      "hobbies": ["Swimming", "Painting"]
    },
    {
      "id": 3,
      "createdAt": "2023-09-19",
      "name": "Bob Johnson",
      "email": "bob@example.com",
      "phone": "555-123-4567",
      "address": "789 Oak Avenue",
      "streetAddress": "Unit 10",
      "city": "Chicago",
      "state": "IL",
      "pinCode": "60601",
      "country": "USA",
      "gender": "Male",
      "hobbies": ["Cooking", "Photography"]
    },
    {
      "id": 4,
      "createdAt": "2023-09-18",
      "name": "Alice Davis",
      "email": "alice@example.com",
      "phone": "888-777-5555",
      "address": "321 Pine Street",
      "streetAddress": "Apt 2C",
      "city": "San Francisco",
      "state": "CA",
      "pinCode": "94101",
      "country": "USA",
      "gender": "Female",
      "hobbies": ["Running", "Chess"]
    },
    {
      "id": 5,
      "createdAt": "2023-09-17",
      "name": "Michael Wilson",
      "email": "michael@example.com",
      "phone": "777-888-9999",
      "address": "555 Elm Street",
      "streetAddress": "Suite 5",
      "city": "Miami",
      "state": "FL",
      "pinCode": "33101",
      "country": "USA",
      "gender": "Male",
      "hobbies": ["Painting", "Traveling"]
    },
    {
      "id": 6,
      "createdAt": "2023-09-16",
      "name": "Sarah Johnson",
      "email": "sarah@example.com",
      "phone": "444-555-6666",
      "address": "999 Oak Avenue",
      "streetAddress": "Unit 8",
      "city": "Austin",
      "state": "TX",
      "pinCode": "73301",
      "country": "USA",
      "gender": "Female",
      "hobbies": ["Reading", "Cycling"]
    },
    {
      "id": 7,
      "createdAt": "2023-09-15",
      "name": "David Lee",
      "email": "david@example.com",
      "phone": "666-777-8888",
      "address": "111 Pine Street",
      "streetAddress": "Apt 3D",
      "city": "Seattle",
      "state": "WA",
      "pinCode": "98101",
      "country": "USA",
      "gender": "Male",
      "hobbies": ["Hiking", "Gaming"]
    },
    {
      "id": 8,
      "createdAt": "2023-09-14",
      "name": "Maria Garcia",
      "email": "maria@example.com",
      "phone": "222-333-4444",
      "address": "222 Oak Avenue",
      "streetAddress": "Suite 12",
      "city": "Denver",
      "state": "CO",
      "pinCode": "80201",
      "country": "USA",
      "gender": "Female",
      "hobbies": ["Dancing", "Music"]
    },
    {
      "id": 9,
      "createdAt": "2023-09-13",
      "name": "Robert Brown",
      "email": "robert@example.com",
      "phone": "999-888-7777",
      "address": "777 Main Street",
      "streetAddress": "Apt 1A",
      "city": "Philadelphia",
      "state": "PA",
      "pinCode": "19101",
      "country": "USA",
      "gender": "Male",
      "hobbies": ["Soccer", "Cooking"]
    },
    {
      "id": 10,
      "createdAt": "2023-09-12",
      "name": "Jennifer White",
      "email": "jennifer@example.com",
      "phone": "111-222-3333",
      "address": "666 Elm Street",
      "streetAddress": "Suite 7",
      "city": "Boston",
      "state": "MA",
      "pinCode": "02201",
      "country": "USA",
      "gender": "Female",
      "hobbies": ["Swimming", "Reading"]
    },
    {
      "id": 11,
      "createdAt": "2023-09-11",
      "name": "William Green",
      "email": "william@example.com",
      "phone": "555-444-3333",
      "address": "888 Oak Avenue",
      "streetAddress": "Unit 15",
      "city": "Atlanta",
      "state": "GA",
      "pinCode": "30301",
      "country": "USA",
      "gender": "Male",
      "hobbies": ["Golf", "Painting"]
    },
    {
      "id": 12,
      "createdAt": "2023-09-10",
      "name": "Linda Wilson",
      "email": "linda@example.com",
      "phone": "777-666-5555",
      "address": "444 Main Street",
      "streetAddress": "Apt 2B",
      "city": "Phoenix",
      "state": "AZ",
      "pinCode": "85001",
      "country": "USA",
      "gender": "Female",
      "hobbies": ["Hiking", "Cooking"]
    },
    {
      "id": 13,
      "createdAt": "2023-09-09",
      "name": "James Anderson",
      "email": "james@example.com",
      "phone": "333-222-1111",
      "address": "555 Pine Street",
      "streetAddress": "Suite 6",
      "city": "Dallas",
      "state": "TX",
      "pinCode": "75201",
      "country": "USA",
      "gender": "Male",
      "hobbies": ["Reading", "Photography"]
    },
    {
      "id": 14,
      "createdAt": "2023-09-08",
      "name": "Susan Miller",
      "email": "susan@example.com",
      "phone": "111-222-3333",
      "address": "777 Elm Street",
      "streetAddress": "Suite 4",
      "city": "Houston",
      "state": "TX",
      "pinCode": "77001",
      "country": "USA",
      "gender": "Female",
      "hobbies": ["Swimming", "Traveling"]
    },
    {
      "id": 15,
      "createdAt": "2023-09-07",
      "name": "Richard Moore",
      "email": "richard@example.com",
      "phone": "444-555-6666",
      "address": "333 Oak Avenue",
      "streetAddress": "Unit 9",
      "city": "Miami",
      "state": "FL",
      "pinCode": "33101",
      "country": "USA",
      "gender": "Male",
      "hobbies": ["Running", "Chess"]
    },
    {
      "id": 16,
      "createdAt": "2023-09-06",
      "name": "Karen Davis",
      "email": "karen@example.com",
      "phone": "666-777-8888",
      "address": "222 Main Street",
      "streetAddress": "Apt 3D",
      "city": "Orlando",
      "state": "FL",
      "pinCode": "32801",
      "country": "USA",
      "gender": "Female",
      "hobbies": ["Cooking", "Gaming"]
    },
    {
      "id": 17,
      "createdAt": "2023-09-05",
      "name": "Daniel Johnson",
      "email": "daniel@example.com",
      "phone": "222-333-4444",
      "address": "111 Pine Street",
      "streetAddress": "Suite 11",
      "city": "Las Vegas",
      "state": "NV",
      "pinCode": "89101",
      "country": "USA",
      "gender": "Male",
      "hobbies": ["Dancing", "Music"]
    },
    {
      "id": 18,
      "createdAt": "2023-09-04",
      "name": "Patricia Smith",
      "email": "patricia@example.com",
      "phone": "999-888-7777",
      "address": "999 Oak Avenue",
      "streetAddress": "Unit 5",
      "city": "San Diego",
      "state": "CA",
      "pinCode": "92101",
      "country": "USA",
      "gender": "Female",
      "hobbies": ["Soccer", "Traveling"]
    },
    {
      "id": 19,
      "createdAt": "2023-09-03",
      "name": "Joseph Brown",
      "email": "joseph@example.com",
      "phone": "111-222-3333",
      "address": "666 Elm Street",
      "streetAddress": "Apt 7A",
      "city": "Portland",
      "state": "OR",
      "pinCode": "97201",
      "country": "USA",
      "gender": "Male",
      "hobbies": ["Swimming", "Cycling"]
    },
    {
      "id": 20,
      "createdAt": "2023-09-02",
      "name": "Laura White",
      "email": "laura@example.com",
      "phone": "555-444-3333",
      "address": "888 Pine Street",
      "streetAddress": "Suite 10",
      "city": "Denver",
      "state": "CO",
      "pinCode": "80201",
      "country": "USA",
      "gender": "Female",
      "hobbies": ["Reading", "Hiking"]
    }
  ];
  
  console.log(datas);
  export default datas;